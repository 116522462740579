import React from 'react';

interface Pages {
    path      : string,
    isPublic  : boolean,
    component : React.LazyExoticComponent<React.FC>
}

let pages : Pages[] = [
    {
        path      : "/kelola/dashboard",
        isPublic  : false,
        component : React.lazy(() => import('../pages/dashboard')),
    }, {
        path      : "/kelola/calon-peserta",
        isPublic  : false,
        component : React.lazy(() => import('../pages/user')),
    }, {
        path      : "/kelola/pengaturan",
        isPublic  : false,
        component : React.lazy(() => import('../pages/setting')),
    }, {
        path      : "/kelola/formulir-manual",
        isPublic  : false,
        component : React.lazy(() => import('../pages/form')),
    }, {
        path      : "/kelola/biaya-pendaftaran",
        isPublic  : false,
        component : React.lazy(() => import('../pages/cost')),
    }, {
        path      : "/kelola/pembendaharaan",
        isPublic  : false,
        component : React.lazy(() => import('../pages/treasury')),
    }, {
        path      : "/",
        isPublic  : true,
        component : React.lazy(() => import('../pages/login')),
    },
    ];

export default pages;