import React from "react";
import {
    LineChartOutlined,
    SettingOutlined,
    TeamOutlined,
    FormOutlined,
    DollarCircleOutlined,
    BookOutlined
} from "@ant-design/icons";

const data = {
    mainMenu : [
        {
            title : "Dashboard",
            link  : "/kelola/dashboard",
            icon  : <LineChartOutlined style={{fontSize : 18}} />
        }, {
            title : "Data Calon Peserta",
            link  : "/kelola/calon-peserta",
            icon  : <TeamOutlined style={{fontSize : 18}} />
        },  {
            title : "Formulir Manual",
            link  : "/kelola/formulir-manual",
            icon  : <FormOutlined style={{fontSize : 18}} />
        }, {
            title : "Pengaturan",
            link  : "/kelola/pengaturan",
            icon  : <SettingOutlined style={{fontSize : 18}} />
        }, {
            title : "Biaya Pendaftaran",
            link  : "/kelola/biaya-pendaftaran",
            icon  : <DollarCircleOutlined style={{fontSize : 18}} />
        }, {
            title : "Pembendaharaan",
            link  : "/kelola/pembendaharaan",
            icon  : <BookOutlined style={{fontSize : 18}} />
        },
    ],
}

export default data